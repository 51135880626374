import classNames from "classnames";
import { formatAsNumber, formatAsPercent } from "../../utils";
import SvgInfo from "../icons/Info";
import SvgWarningAmber from "../icons/WarningAmber";
import styles from "./CalculatedDataCell.module.css";
import Icon from "./Icon";

interface Props {
  className?: string;
  format?: "number" | "percentage";
  value?: number;
  type?: "default" | "info" | "warning";
  title?: string;
}

const CalculatedDataCell = ({
  className,
  format = "number",
  value,
  type = "default",
  title,
}: Props) => (
  <td
    className={classNames(styles.cell, className, {
      [styles.warning]: type === "warning",
    })}
    title={title}
  >
    <div
      className={classNames(styles.cellContent, {
        [styles.withIcon]: type === "info" || type === "warning",
      })}
    >
      {type === "warning" && (
        <Icon glyph={SvgWarningAmber} className={styles.icon} />
      )}
      {type === "info" && <Icon glyph={SvgInfo} className={styles.icon} />}
      {value !== undefined &&
        (format === "percentage" ? (
          <Percentage value={value} />
        ) : (
          formatAsNumber(value)
        ))}
    </div>
  </td>
);

interface PercentageProps {
  value: number;
}

const Percentage = ({ value }: PercentageProps) => (
  <span
    className={classNames({
      [styles.zero]: value === 0,
      [styles.negative]: value < 0,
    })}
  >
    {formatAsPercent(2, value)}
  </span>
);

export default CalculatedDataCell;
